import { FixedNumber } from '@ethersproject/bignumber'
import { findLast } from 'lodash-es'
export const Zero = FixedNumber.from(0)
export const FULL_100 = FixedNumber.from(100)
export const MB_TO_BYTE = 1024 * 1024
// max size upload for posting = 500MB
export const MAX_SIZE_IMAGE_UPLOAD_BY_MB = 500
export const MAX_SIZE_IMAGE_UPLOAD_BY_BYTE = MB_TO_BYTE * MAX_SIZE_IMAGE_UPLOAD_BY_MB

const lst = [
  {
    amount: 0,
    period: 0,
    text: 'None Tier',
    tierIndex: 0,
    goldenTickeNumber: 0,
  },
  {
    amount: 1000,
    period: 0,
    text: 'TIER 1',
    tierIndex: 1,
    goldenTickeNumber: 1,

    ticketNumber: 4,
  },
  {
    amount: 5000,
    period: 0,
    text: 'TIER 2',
    tierIndex: 2,
    goldenTickeNumber: 2,
    ticketNumber: 8,
  },
  {
    amount: 10000,
    period: 30,
    text: 'TIER 3',
    tierIndex: 3,
    goldenTickeNumber: 3,
    ticketNumber: 12,
  },
  {
    amount: 25000,
    period: 40,
    text: 'TIER 4',
    tierIndex: 4,
    goldenTickeNumber: 5,
    ticketNumber: 15,
  },
  {
    amount: 50000,
    period: 50,
    text: 'TIER 5',
    tierIndex: 5,
    goldenTickeNumber: 10,
    ticketNumber: 20,
  },
]

export const DEFAULT_REVIEW_CATEGORIES = [
  { id: '632a83f1ecd49ca65b22f0e9', label: 'Education', type: 'education' },
  { id: '632a8412ecd49ca65b22f0ea', label: 'Travel', type: 'travel' },
  { id: '632a8434ecd49ca65b22f0eb', label: 'Deep-fi', type: 'deep-fi' },
  { id: '632a8625bd6b7da76e220280', label: 'NFT Lover', type: 'nft-lover' },
  { id: '632a8625bd6b7da76e220281', label: 'Writing', type: 'writing' },
  { id: '632a8625bd6b7da76e220282', label: 'Sports', type: 'sports' },
  { id: '632a8625bd6b7da76e220283', label: 'Gaming', type: 'gaming' },
  { id: '632a8625bd6b7da76e220284', label: 'Crypto', type: 'crypto' },
  { id: '632a8625bd6b7da76e220285', label: 'Technology', type: 'technology' },
  { id: '632a8625bd6b7da76e220286', label: 'Music', type: 'music' },
  { id: '632a8625bd6b7da76e220287', label: 'Social Media', type: 'social-media' },
  { id: '632a8625bd6b7da76e220288', label: 'Food', type: 'food' },
  { id: '632a8625bd6b7da76e220289', label: 'Business', type: 'business' },
  { id: '632a8625bd6b7da76e22028a', label: 'X2E', type: 'x2e' },
  { id: '632a8625bd6b7da76e22028b', label: 'Startup', type: 'startup' },
  { id: '632a8625bd6b7da76e22028c', label: 'Towards Data Science', type: 'towards-data-science' },
  { id: '632a8625bd6b7da76e22028d', label: 'Personal Growth', type: 'personal-growth' },
  { id: '632a8625bd6b7da76e22028e', label: 'BetterHumans', type: 'better-humans' },
  { id: '632a8625bd6b7da76e22028f', label: 'The Economist', type: 'the-economist' },
  { id: '632a8625bd6b7da76e220290', label: 'Better Marketing', type: 'better-marketing' },
  { id: '632a8625bd6b7da76e220291', label: 'Health', type: 'health' },
  { id: '632a8625bd6b7da76e220292', label: 'Design', type: 'design' },
  { id: '632a8625bd6b7da76e220293', label: 'Jounalism', type: 'jounalism' },
  { id: '632a8625bd6b7da76e220294', label: 'Art', type: 'art' },
]
function getTierList() {
  return lst.map((x) => ({
    ...x,
  }))
}
function findTierByAmount(stakedAmount: number) {
  return findLast(lst, (x) => stakedAmount >= x.amount)
}
function findTierByTierIndex(tierIndex: number) {
  return findLast(lst, (x) => tierIndex === x.tierIndex) || lst[0]
}
export const tierHelper = { getTierList, findTierByAmount, findTierByTierIndex }

export const MINT_MEMBER_TYPE = {
  public: 0,
  whitelist: 1,
}
