import { localData } from "@/stores/local-data"
import axios from "axios"
import { ApiRouteType } from "../types"

export class ReportHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {
      //
    }
  
    async findOne<T>(id: any): Promise<T> {
      let res: any
      if (id) {
        res = await this.axios.get(`${this.route}/${id}`)
      } else {
        res = await this.axios.get(`${this.route}`)
      }
      const result = res.data
      return result
    }
  
    async createReport(model): Promise<T> {
      const res = await axios.post(`create-report`, model, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
      return res.data
    }
  }