import { promiseHelper } from '@/helper/promise-helper'
import { apiService } from '@/services/api-services'
import { localData } from '@/stores/local-data'
import { connector, walletStore } from '@/stores/wallet-store'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { algoClient } from '@/algo-client'
import algosdk, { Algodv2 } from 'algosdk'
import { encode, decode } from 'uint8-to-base64'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import { checkDeviceType } from '@/helper/ua-parser'
import { snackController } from '../snack-bar/snack-bar-controller'
import QRCodeModal from 'algorand-walletconnect-qrcode-modal'

class WalletSettingController {
  @observable isOpenDialog = false
  @observable confirming = false

  @observable emailAddress = ''
  @observable walletAddress = ''
  @observable selectedWalletType = ''
  @observable username = ''
  @observable showWalletDialog = false
  @observable allowChangeWallet = false
  @observable profileRegisted = false

  @action.bound onUsernameChange(val = '') {
    this.username = val
  }

  @action reset() {
    this.onUsernameChange()
  }

  @action showConnectWalletDialog(val) {
    this.showWalletDialog = val;
  }

  @asyncAction *confirm() {
    try {
      this.confirming = true
      yield walletStore.signUpWallet(this.walletAddress, false)
      const signatureTx = yield this.signMessage()
      if (this.profileRegisted) {
        yield walletStore.updateUserProfile(
          { id: walletStore.userProfile?._id, username: this.username, walletAddress: this.walletAddress, signatureTx, email: this.emailAddress }
        )
      } else {
        yield walletStore.createUserProfile({ userName: this.username, walletAddress: this.walletAddress, signatureTx, email: this.emailAddress })
      }
    } catch (error) {
    } finally {
      this.confirming = false
    }
    this.setOpenDialog(false)
  }

  @asyncAction *connectWallet() {
    ///
  }

  @asyncAction *connectMyAlgoWallet() {
    try {
      const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
      const settings = {
        shouldSelectOneAccount: true,
        openManager: true,
      }
      const accounts = yield myAlgoConnect.connect(settings)
      if (accounts.length) {
        this.walletAddress = accounts[0]?.address
        this.username = this.username || accounts[0]?.name || ''
      }
      this.selectedWalletType = 'my-algo-wallet'
      this.showConnectWalletDialog(false)
    } catch (e) {
      console.log(e.toString())
    }
  }

  @asyncAction *connectViaWalletConnect() {
    try {
      if (!connector.connected) {
        connector.createSession()
      }
      if (connector.pending) {
        QRCodeModal.open(connector.uri, null)
      }
      connector.on('connect', (error, payload) => {
        runInAction(() => {
          this.walletAddress = payload?.params[0].accounts[0]
          this.selectedWalletType = 'pera-wallet'
          this.showConnectWalletDialog(false)
          QRCodeModal.close()
        })
        if (error) {
          throw error
        }
      })

      connector.on('session_update', (error, payload) => {
        if (error) {
          throw error
        }
      })

      connector.on('disconnect', (error, payload) => {
        if (error) {
          throw error
        }
        if (payload.params[0]?.message === 'Session update rejected') snackController.error('User reject request')
      })
    } catch (error) {
      snackController.commonError(error)
    } finally {
      //
    }
  }

  @asyncAction *connectAlgoSigner() {
    if (typeof (window as any).AlgoSigner !== 'undefined') {
      yield (window as any).AlgoSigner.connect()
      const accounts = yield (window as any).AlgoSigner.accounts({
        ledger: 'TestNet',
      })
      if (accounts.length) {
        this.walletAddress = accounts[0]?.address
        this.username = this.username || accounts[0]?.name || ''
      }
      this.selectedWalletType = 'algo-signer'
      console.log(toJS(accounts))
      this.showConnectWalletDialog(false)
    } else {
      ; (window as any)
        .open('https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm', '_blank')
        .focus()
    }
  }

  @asyncAction *signMessage() {
    try {
      if (!this.walletAddress) return null
      const user = yield apiService.users.find({ username: this.walletAddress })
      const nonce = user[0]?.nonce
      const message = `https://algolaunch.io wants to: \n Sign message with account \n ${this.walletAddress} - One time nonce: ${nonce}`
      const encoder = new TextEncoder()
      const messageEncoded = encoder.encode(message)

      const params = yield algoClient?.getTransactionParams().do()
      const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: this.walletAddress,
        to: this.walletAddress,
        amount: 0,
        note: messageEncoded,
      })

      if (this.selectedWalletType === 'algo-signer') {
        const txn_b64 = (window as any).AlgoSigner.encoding.msgpackToBase64(txn.toByte())
        const signedTxs = yield (window as any).AlgoSigner.signTxn([{ txn: txn_b64 }])
        return { signedTxn: signedTxs[0].blob, publicAddress: this.walletAddress }
      } else if (this.selectedWalletType === 'my-algo-wallet') {
        const myAlgoConnect = new MyAlgoConnect()
        const signedTxns = yield myAlgoConnect.signTransaction(txn.toByte())
        const signedTxn = signedTxns.blob
        const signedTxnBase64 = encode(signedTxn)
        return { signedTxn: signedTxnBase64, publicAddress: this.walletAddress, nonce }
      } else if (this.selectedWalletType === 'pera-wallet') {
        const txns = [txn]
        const txnsToSign = txns.map((txn) => {
          const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
          return {
            txn: encodedTxn,
          }
        })
        const requestParams = [txnsToSign]
        const request = formatJsonRpcRequest('algo_signTxn', requestParams)
        const deviceType = checkDeviceType()
        //Request open Pera Wallet application on mobile browser
        if (deviceType?.device?.type === 'mobile') {
          const deepLink = deviceType?.os?.name === 'iOS' ? 'algorand-wc://' : 'algorand://'
          window.location.href = deepLink
        }
        const result: Array<string | null> = yield connector.sendCustomRequest(request)
        const decodedResult = result.map((element) => {
          return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
        })
        const signedTxn = encode(decodedResult[0])
        if (!signedTxn) {
          snackController.error('Have sign message error. Please try again!')
        }
        return { signedTxn: signedTxn, publicAddress: this.walletAddress, nonce }
      }
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  // DIALOG
  @action.bound setOpenDialog(val: boolean) {
    const profileInfo = walletStore.userProfile
    if (this.isOpenDialog && !val) {
      // if has not register => disable close dialog
      if (!profileInfo) {
        return
      }
    }
    this.emailAddress = walletStore.userInfo?.email || ''
    this.walletAddress = walletStore.userProfile?.walletAddress || ''
    this.profileRegisted = !!walletStore.userProfile
    this.allowChangeWallet = !this.walletAddress
    this.username = walletStore.userProfile?.username || ''
    if (!this.username) {
      this.username = walletStore.userInfo?.username || ''
      const pref = `${walletStore.userInfo?.provider}___`
      if (this.username.startsWith(pref)) {
        this.username = this.username.substring(pref.length)
      }
    }
    if (this.confirming) return
    this.isOpenDialog = val
  }
}

export const walletSettingController = new WalletSettingController()