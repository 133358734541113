import { promiseHelper } from '@/helper/promise-helper'
import { rules } from '@/plugins/rules'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { snackController } from '../snack-bar/snack-bar-controller'

class AccountSettingController {
  @observable isOpenDialog = false
  @observable confirming = false

  @observable emailAddress = ''
  @observable username = ''

  @observable registed = false

  // CONFIRM
  @action.bound onEmailAddressChange(val = '') {
    this.emailAddress = val
  }

  @action.bound onUsernameChange(val = '') {
    this.username = val
  }

  @action reset() {
    this.onEmailAddressChange()
    this.onUsernameChange()
  }

  @asyncAction *confirm() {
    try {
      this.confirming = true
      // if (this.emailAddress) {
      //   if (rules.email(this.emailAddress) === true) {
      //     if (this.emailAddress !== walletStore.userProfile?.email) {
      //       const userEmail = yield apiService.users.find({ email: this.emailAddress })
      //       if (userEmail.length) {
      //         snackController.error("Email is existed")
      //         return
      //       }
      //     }
      //   } else {
      //     snackController.error(`"${this.emailAddress}" is not email`)
      //     return
      //   }
      // }
      if (this.registed) {
        yield walletStore.updateUserProfile({ id: walletStore.userProfile?._id, username: this.username })
      } else {
        yield walletStore.createUserProfile({ userName: this.username })
      }
    } catch (error) {
    } finally {
      this.confirming = false
    }
    this.setOpenDialog(false)
  }

  // DIALOG
  @action.bound setOpenDialog(val: boolean) {
    const profileInfo = walletStore.userProfile
    if (this.isOpenDialog && !val) {
      // if has not register => disable close dialog
      if (!profileInfo) {
        return
      }
    }
    this.emailAddress = walletStore.userProfile?.email || ''
    this.username = walletStore.userProfile?.username || ''
    this.registed = !!walletStore.userProfile
    if (this.confirming) return
    this.isOpenDialog = val
  }
}

export const accountSettingController = new AccountSettingController()