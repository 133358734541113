import { localData } from "@/stores/local-data"
import { ApiRouteType } from "../types"

export class CommentHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
  
    async findOne<T>(id: any): Promise<T> {
      let res: any
      if (id) {
        res = await this.axios.get(`${this.route}/${id}`)
      } else {
        res = await this.axios.get(`${this.route}`)
      }
      const result = res.data
      return result
    }
  
    async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(this.route, { params })
      const lst = res.data
      return lst
    }
  
    async createComment(model: T): Promise<T> {
      const res = await this.axios.post('comments/createComment', model, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
      return res.data
    }
  
    async fetchComments<T>(
      params?: any,
      settings: { _sort?: string; _limit?: number; _start?: number } = {}
    ): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: 12, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get('/fetchComments', { params })
      const lst = res.data
      return lst
    }
  }