import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { promiseHelper } from '@/helper/promise-helper'
import { CommentModel, OgInfo, PollModel, PostContentBlock, PostModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { isEmpty, isEqual, map, orderBy, reduce, reverse, sortBy, uniqueId } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { dispatcher, FollowingProfile, FollowTypeEnum } from './dispatcher'
import { postMediasDetailStore } from './post-medias-detail-store'
import { postShareStore } from './post-share'
import { walletStore } from './wallet-store'

export class PostStore {
  @observable post: PostModel
  @observable commentInput = ''
  @observable mentionUsers: string[] = []
  @observable allComments: CommentModel[] = []
  @observable ogInfo?: OgInfo = undefined
  @observable isEnableComment = false

  @observable contentBlocks: PostContentBlock[] = []
  @observable fetchingComment = false
  @observable currentVote: number | undefined = undefined
  @observable poll?: PollModel = undefined
  private _unsubcrible = new Subject()

  constructor(post: PostModel) {
    this.post = post
    this.currentVote = this.post?.yourVote?.voteOption
    this.poll = this.post?.poll

    this.fetchContent()
    this.allComments = this.post.comments || []

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.user && this.post?.profile?.id === profile.to) {
      this.post = { ...this.post, isFollowing: profile.followState, loading: false }
    }
  }

  @action.bound setMentionUsers(users) {
    if (!isEqual(sortBy(toJS(this.mentionUsers)), sortBy(users))) {
      this.mentionUsers = [...users]
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @asyncAction *handleLike() {
    try {
      if (!this.post.isLike) {
        this.post.isLike = !this.post.isLike
        this.post.likeCount = (this.post.likeCount as number) + 1
        yield apiService.posts.like({ post: this.post })
      } else {
        this.post.isLike = !this.post.isLike
        this.post.likeCount = (this.post.likeCount as number) - 1
        yield apiService.posts.unlike({ post: this.post })
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action changeEnableComment() {
    this.isEnableComment = !this.isEnableComment
    if (this.isEnableComment) this.fetchAllComments()
  }

  @asyncAction *fetchAllComments() {
    try {
      if (this.fetchingComment) return
      this.fetchingComment = true
      this.allComments = yield apiService.comments.find({ post: this.post.id })
    } catch (error) {
      this.fetchingComment = false
    }
  }

  @asyncAction *handleComment() {
    try {
      if (this.commentInput) {
        const profile = walletStore.userInfo?.profile
        const fakeId = uniqueId()
        let newComment = { id: fakeId, content: this.commentInput, owner: profile, createdAt: moment().toISOString() }
        this.allComments = [...this.allComments, newComment]
        const inputTmp = this.commentInput
        this.commentInput = ''
        if (profile && inputTmp) {
          newComment = yield apiService.comments.createComment({
            post: this.post,
            content: inputTmp,
            mentionUsers: this.mentionUsers,
          })
          this.allComments = [...this.allComments, newComment].filter((x) => x.id !== fakeId)
          this.post.commentCount = (this.post.commentCount as number) + 1
          this.mentionUsers = []
        }
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *handleReplyTo(comment: CommentModel) {
    try {
      if (comment.replyingText) {
        const profile = walletStore.userInfo?.profile

        const fakeId = uniqueId()
        let newComment = {
          id: fakeId,
          content: comment.replyingText,
          owner: profile,
          replyTo: { id: comment.id },
          createdAt: moment().toISOString(),
        }

        this.allComments = [newComment, ...this.allComments]
        comment.totalReply = (comment.totalReply || 0) + 1

        const inputTmp = comment.replyingText
        comment.replyingText = ''
        if (profile && inputTmp) {
          newComment = yield apiService.comments.createComment({
            post: this.post,
            content: inputTmp,
            mentionUsers:  comment.replyingMentionUsers,
            replyTo: comment.id,
          })
          this.allComments = [newComment, ...this.allComments].filter((x) => x.id !== fakeId)
          comment.replyingMentionUsers = []
          this.post.commentCount = (this.post.commentCount as number) + 1
        }
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action handleSharePost() {
    postShareStore.handleSharePost(this.post)
  }

  @asyncAction *fetchOpenGraphInfo(url: string) {
    this.ogInfo = yield apiService.posts.getOpenGraphInfo(url)
  }

  @asyncAction *followUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.profile.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.profile.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action openEmbedLink() {
    window.open(this.firstOpenGraphInfo?.requestUrl, '_blank')
  }

  @action changeOpenPostMediasDetail(forceHide = false, value, index = -1) {
    if (forceHide && value) return
    if (value) {
      postMediasDetailStore.init(this)
      postMediasDetailStore.changeOpenPostMediasDetail(forceHide, value, index)
    } else postMediasDetailStore.changeOpenPostMediasDetail(forceHide, value)
  }

  @asyncAction *handleVotePoll(index) {
    try {
      this.currentVote = index
      yield apiService.votes.vote({
        pollId: this.poll?.id,
        voteOption: index,
      })
      this.poll = yield apiService.polls.findOne(this.poll?.id)
    } catch (e) {
      console.log('vote error: ', e)
    }
  }

  @asyncAction *handleBookmark() {
    try {
      if (!this.post.isBookmarked) {
        this.post.isBookmarked = !this.post.isBookmarked
        yield apiService.bookmarks.createBookmark({ postId: this.post?.id as string })
      } else {
        this.post.isBookmarked = !this.post.isBookmarked
        yield apiService.bookmarks.removeBookmark({ postId: this.post?.id as string })
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *postViewIncrement() {
    if (this.post.id) yield apiService.posts.postViewIncrement(this.post.id)
  }

  @asyncAction *linkViewIncrement(link: string) {
    if (this.post.id && link) yield apiService.links.increaseLinkView({ postId: this.post.id, link })
  }

  @computed get medias() {
    return this.post?.medias || []
  }

  @computed get imageLinks() {
    const images = this.medias?.filter((item) => item?.mime?.includes('image'))
    return map(images, 'url')
  }
  @computed get videoLinks() {
    const videos = this.medias?.filter((item) => item?.mime?.includes('video'))
    return map(videos, 'url')
  }

  @computed get link() {
    return this.post?.link
  }

  @computed get content() {
    return !isEmpty(this.post.content) ? this.post.content : ''
  }

  // FIXED ME
  @asyncAction *fetchContent() {
    this.contentBlocks = this.post?.data?.content || []
    const linkIndexs = [] as any
    const getLPromises = [] as any
    this.contentBlocks?.map((item, index) => {
      if (item.type === 'link' && item.link) {
        linkIndexs.push(index)
        getLPromises.push(apiService.posts.getOpenGraphInfo(item.link))
      }
    })
    if (!linkIndexs.length) return
    const res = yield Promise.all(getLPromises)
    res.map((item, index) => {
      this.contentBlocks[linkIndexs[index]]['ogInfo'] = item
    })
  }

  @computed get title() {
    return this.post?.title
  }

  // @computed get mediaContents() {
  //   const mediaContentBlocks = this.contentBlocks?.filter((item) => item.type === 'image' || item.type === 'video')
  //   const mediaFiles = map(mediaContentBlocks, 'file')
  //   return flatMapDeep(mediaFiles)
  // }

  @computed get contentMedias() {
    const medias = this.post?.medias || []
    const formatMedias = [] as any
    medias.map((item) => {
      formatMedias[item._id] = item
    })
    return formatMedias
  }

  @computed get concatedTagContent() {
    const tagContents = map(this.post?.tags, 'content') || []
    const res = reduce(tagContents, (previousVal, currentVal) => previousVal + `${currentVal}  `, '')
    return res
  }
  @computed get isPostOwner() {
    return this.post?.profile?._id === walletStore.userProfile?._id
  }

  @computed get commentsOrderByLatest() {
    return this.allComments.reverse()
  }
  @computed get firstMediaFile() {
    let mediaFile = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'image' && block.files[0][0]) {
        const id = block.files[0][0]
        mediaFile = { media: this.contentMedias[id], type: 'image' }
        break
      } else if (block.type === 'video' && block.files[0]) {
        const id = block.files[0]
        mediaFile = { media: this.contentMedias[id], type: 'video' }
        break
      }
    }
    return mediaFile
  }
  @computed get firstImage() {
    let mediaFile = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'image' && block.files[0][0]) {
        const id = block.files[0][0]
        mediaFile = this.contentMedias[id]
        break
      }
    }
    return mediaFile
  }
  @computed get firstOpenGraphInfo() {
    let ogInfo = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'link' && block['ogInfo']) {
        ogInfo = block['ogInfo']
        break
      }
    }
    return ogInfo
  }
  @computed get firstTextBlock() {
    // let htmlContent = '' as any
    // for (let i = 0; i < this.contentBlocks.length; i++) {
    //   const block = this.contentBlocks[i]
    //   if (block.type === 'text' && block.rawContent) {
    //     htmlContent = block.htmlContent
    //   }
    // }
    let htmlContent = '' as any
    const content = this.post?.data?.content || []
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === 'text' && content[i].rawContent) {
        htmlContent = content[i].htmlContent
      }
    }
    return htmlContent
  }

  @computed get openGraphLinkImage() {
    return this.firstOpenGraphInfo?.ogImage?.url
  }

  @computed get pollOptions() {
    return this.poll?.data?.options
  }
  @computed get yourVote() {
    return this.post?.yourVote
  }
  @computed get pollDuration() {
    return this.poll?.data?.durationTime
  }
  @computed get totalVotes() {
    return this.poll?.totalVotes
  }
  @computed get location() {
    return this.post?.location
  }
  @computed get mapLocation() {
    return this.post?.mapLocation
  }
  @computed get isPollEnded() {
    const startTime = this.poll?.data?.startTime
    if (!startTime || !this.pollDuration) return false

    const days = this.pollDuration?.days || 0
    const hours = this.pollDuration?.hours || 0
    const minutes = this.pollDuration?.minutes || 0
    const pollDuration = moment.duration({
      days: +days,
      hours: +hours,
      minutes: +minutes,
    })
    return moment(startTime).add(pollDuration).isBefore(moment())
  }

  @computed get firstMedia() {
    return (this.post?.medias || [])[0]
  }

  @computed get firstMediaRepost() {
    return (this.post?.rePost?.medias || [])[0]
  }

  @computed get firstTextBlockRepost() {
    let htmlContent = '' as any
    const content = this.post?.rePost?.data?.content || []
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === 'text' && content[i].rawContent) {
        htmlContent = content[i].htmlContent
      }
    }
    return htmlContent
  }

  @computed get repostedPost() {
    return this.post?.rePost
  }

  @computed get repostTitle() {
    return this.post?.rePost?.title
  }

  @computed get repostProfile() {
    return this.post?.rePost?.profile
  }

  @computed get repostCreatedAt() {
    return this.post?.rePost?.createdAt
  }

  @computed get firstMediaRepostIsImage() {
    return this.firstMediaRepost?.mime?.includes('image')
  }

  @computed get firstMediaRepostIsVideo() {
    return this.firstMediaRepost?.mime?.includes('video')
  }

  @computed get reports() {
    return reverse(this.post?.reports || [])
  }

  @computed get isBlind() {
    return this.post.isBlind
  }

  @computed get lastReportedTime() {
    return this.post?.lastReportedTime || ''
  }

  @computed get rootComments() {
    return orderBy(this.allComments, ['createdAt'], ['desc'])
      .filter((x) => !x.replyTo)
      .map((x) => {
        const replyComments = this.allComments.filter((y) => y.replyTo && y.replyTo.id === x.id)
        // if (x.totalReply && x.totalReply > 0) {
        //   console.log(replyComments, this.allComments.filter(x => x.replyTo))
        // }
        return { comment: x, replyComments: orderBy(replyComments, ['createdAt']) }
      })
  }
}
