import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import {
  DEFAULT_REVIEW_CATEGORIES,
  MAX_SIZE_IMAGE_UPLOAD_BY_BYTE,
  MAX_SIZE_IMAGE_UPLOAD_BY_MB,
  MB_TO_BYTE,
} from '@/constants'
import { numberHelper } from '@/helper/number.hepler'
import { b64toBlob, formatStringNoExtraSpace, objIsNotEmpty } from '@/helper/utils'
import { CommunityModel } from '@/models/community-model'
import { PostModel, TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import _, { chunk, flattenDeep, map, now, some, sumBy } from 'lodash'
import { action, computed, observable, runInAction, toJS, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
const DEFAULT_POLL_VOTES = [
  {
    id: 0,
    label: 'Option 1',
    textInput: '',
    error: '',
  },
  {
    id: 1,
    label: 'Option 2',
    textInput: '',
    error: '',
  },
]

export interface EditorBlock {
  tagLocation?: any
  type?: string
  id?: number
  options?: any
  pureFile?: any
  fileSource?: any
  pureFiles?: any
  fileSources?: any
  htmlContent?: string
  rawContent?: string
  insertOgInfo?: any
  link?: string
  letterCount?: number
}
export interface PostMediaSource {
  id?: string
  type?: string
  source?: string
  name?: string
  sizeByByte: number
  sizeByMb?: number
  isExceededSize?: boolean
  isStopVideo?: boolean
  isEditMediaSource?: boolean
  durationBySecond?: number
}

export interface PostMediaFile {
  id?: string
  file?: File
  isEditMediaFile?: boolean
}
const DEFAULT_AUDIENCE_DETAILS = [
  {
    id: 'general',
    title: 'General',
  },
]

// { type: 'announcement', label: 'Announcement' },
export class CreatePostController {
  @observable completedUpdateType: undefined | 'create' | 'modify' | 'draft' = undefined
  @observable createPostDialog = false
  @observable tagLocationDialog = false
  @observable isEditPost = false
  @observable isEnableEditPoll = true
  @observable postId: any = ''

  @observable searchLocationResult = [] as any
  @observable taggedLocation = undefined

  @observable postTitle = ''
  @observable linkInserting = false
  @observable tagList: TagModel[] = []
  @observable tagContentList: string[] = []

  @observable tagTextSearch = ''
  @observable tagsSelected: string[] = []

  //dialog loading
  @observable createPostLoading = false
  @observable editPostLoading = false

  @observable selectedPost?: PostModel = undefined

  @observable isReadyVideoDuration = false
  @observable editorBlocks: EditorBlock[] = []
  @observable editorBlockCount = 0

  //poll section

  @observable isPollSelected = false
  @observable pollCount = 2
  @observable pollVotes = DEFAULT_POLL_VOTES
  @observable pollDurationByDay: any = ''
  @observable pollDurationByHour: any = ''
  @observable pollDurationByMinute: any = ''

  @observable pollDurationByDayError = ''
  @observable pollDurationByHourError = ''
  @observable pollDurationByMinuteError = ''
  @observable isCreatePost = true
  @observable type?: 'modify' | 'draft' = undefined
  @observable communityId?: string = undefined
  @observable community?: CommunityModel = undefined
  @observable relatedCommunities: CommunityModel[] = []
  @observable getAllRelatedDaosLoading = false

  // audience
  @observable selectedDao?: CommunityModel = undefined
  @observable audience?: 'everyone' | 'review' | 'my-dao' = undefined
  @observable audienceError = ''
  @observable isValidPostType = false

  @observable audienceDetails = DEFAULT_AUDIENCE_DETAILS as any
  @observable audienceDetail = undefined as any
  @observable audienceDetailError = ''

  reviewCategories = DEFAULT_REVIEW_CATEGORIES
  @observable selectedReviewCategories = []
  @observable reviewCategoryError = ''
  @observable reviewRating?: number = undefined
  @observable reviewRatingError = ''
  @observable tagLocation: any = undefined
  @observable fetchPlaceDetailLoading = false
  @observable placeLoadingIndex = 0
  @observable repostedPostStore?: PostStore
  @observable isExpandReviewTip = false
  @observable isValidInput = true
  @observable isValidLetterCount = true
  @observable openConfirmDiscardPostDialog = false
  @observable openReviewGuidelineDialog = false

  @action resetRelatedAudienceFields() {
    this.audience = undefined
    this.selectedDao = undefined
    this.audienceDetails = DEFAULT_AUDIENCE_DETAILS
    this.audienceDetail = undefined
    this.reviewRating = undefined
    this.selectedReviewCategories = []
    this.isExpandReviewTip = false
  }

  @action clearPostData() {
    this.postTitle = ''
    this.tagTextSearch = ''
    this.tagsSelected = []
    this.isReadyVideoDuration = false
    this.isEditPost = false
    this.type = undefined
    this.tagLocation = undefined
    this.community = undefined
    this.communityId = undefined

    this.initEditorBlocks()
    this.selectedPost = undefined
    this.repostedPostStore = undefined
    this.clearPoll()
  }

  @action clearPoll() {
    this.isPollSelected = false
    this.pollVotes = DEFAULT_POLL_VOTES.map((item) => ({ ...item }))
    this.pollDurationByDay = ''
    this.pollDurationByHour = ''
    this.pollDurationByMinute = ''
    this.pollDurationByDayError = ''
    this.pollDurationByHourError = ''
    this.pollDurationByMinuteError = ''
    this.isEnableEditPoll = true
  }

  @action changeOpenConfirmDiscardPostDialog(value) {
    this.openConfirmDiscardPostDialog = value
  }

  @action changeOpenReviewGuidelineDialog(value) {
    this.openReviewGuidelineDialog = value
  }

  @action onDiscardPost() {
    this.openConfirmDiscardPostDialog = false
    this.show(false)
  }

  @action.bound show(
    value: boolean,
    post: PostModel | undefined = undefined,
    type: undefined | 'modify' | 'draft' = undefined,
    community: CommunityModel | undefined = undefined,
    repostedPostStore: PostStore | undefined = undefined
  ) {
    this.createPostDialog = value
    this.clearPostData()
    this.fetchTags()
    this.resetRelatedAudienceFields()
    this.getAllRelatedDaos()
    this.resetPostTypeError()
    if (post) {
      this.selectedPost = post
      this.type = type
      this.postId = post.id
      this.isEnableEditPoll = false
      this.isEditPost = true
      if (post.location && post.mapLocation)
        this.tagLocation = {
          location: post.location,
          sourceImage: post.mapLocation.url,
        }
      if (post.rePost && !repostedPostStore) {
        this.repostedPostStore = new PostStore(post.rePost)
      }

      this.setPostData(post)
      this.createPostDialog = true
      this.audience = post.type
      if (this.isReviewAudience) {
        this.changeRewviewCategory(post.reviewCategories)
        this.changeReviewRating(post.reviewRating)
      }
    }
    if (repostedPostStore) {
      this.repostedPostStore = repostedPostStore
      if (repostedPostStore.post?.dao) {
        this.setCommunityInfo(repostedPostStore.post?.dao)
      }
    } else {
      this.setCommunityInfo(community || post?.dao, post)
    }
  }

  @action setCommunityInfo(community?: CommunityModel, post: PostModel | undefined = undefined) {
    if (!community) return
    this.community = community
    this.communityId = community?._id
    this.setCommunityMenu(community)
    this.selectedDao = community
    this.audience = 'my-dao'
    if (post && post?.daoMenu) {
      this.audienceDetail = this.audienceDetails.find((item) => item.id === post.daoMenu)?.id
    }
  }

  @action changeTagLocationDialog(value) {
    if (value) {
      this.tagLocationDialog = true
      this.searchLocationResult = []
    } else {
      this.tagLocationDialog = false
    }
  }

  @action selectAudienceChange(obj: { type?: 'everyone' | 'review' | 'my-dao'; selectedDao?: CommunityModel }) {
    this.audience = obj.type
    this.selectedDao = obj.selectedDao
    if (this.selectedDao) this.setCommunityMenu(this.selectedDao)
    this.audienceDetail = undefined
    this.reviewRating = undefined
    this.audienceError = ''
    this.audienceDetailError = ''
    this.selectedReviewCategories = []
    this.isExpandReviewTip = false
  }

  @action.bound toggleExpandReviewTip() {
    this.isExpandReviewTip = !this.isExpandReviewTip
  }

  @action changeSearchLocationResult(locations) {
    const validLocations = locations.filter((item) => !!item.place_id)
    const res =
      validLocations?.map((item) => {
        const main_text = item?.structured_formatting?.main_text
        return {
          mainText: main_text,
          secondaryText: item?.structured_formatting?.secondary_text || main_text,
          placeId: item.place_id,
        }
      }) || []
    this.searchLocationResult = res
  }

  @asyncAction *changeTagLocation(location, index) {
    try {
      if (this.fetchPlaceDetailLoading) return
      this.fetchPlaceDetailLoading = true
      this.placeLoadingIndex = index
      const res = yield apiService.posts.fetchPlaceDetail({
        fields: ['name', 'geometry'],
        placeId: location.placeId,
      })
      this.tagLocation = {
        location,
        sourceImage: `data:image/png;base64, ${res}`,
        base64Source: res,
      }
      this.addLocationBlock()
      this.changeTagLocationDialog(false)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.fetchPlaceDetailLoading = false
    }
  }

  @action set;

  @action changeAudienceDetail(input) {
    this.audienceDetail = input
    this.audienceDetailError = ''
  }

  @action changeRewviewCategory(input) {
    this.selectedReviewCategories = input
    this.reviewCategoryError = ''
  }

  @action changeReviewRating(value) {
    this.reviewRating = value
    this.reviewRatingError = ''
  }

  @action resetPostTypeError() {
    this.isValidInput = true
    this.isValidLetterCount = true
    this.audienceError = ''
    this.reviewRatingError = ''
    this.reviewCategoryError = ''
    this.audienceDetailError = ''
  }

  @action verifyPostType() {
    if (!this.audience) {
      this.audienceError = 'invalid'
      this.isValidInput = false
    }
    switch (this.audience) {
      case 'review':
        if (!objIsNotEmpty(this.selectedReviewCategories)) {
          this.reviewCategoryError = 'Invalid'
          this.isValidInput = false
        }
        if (!objIsNotEmpty(this.reviewRating)) {
          this.reviewRatingError = 'Invalid'
          this.isValidInput = false
        }
        break
      case 'my-dao':
        if (!objIsNotEmpty(this.audienceDetail)) {
          this.audienceDetailError = 'Invalid'
          this.isValidInput = false
        }
        break
    }
  }

  @computed get isSelectedMyDao() {
    return this.audience === 'my-dao'
  }

  @computed get isReviewAudience() {
    return this.audience === 'review'
  }

  @asyncAction *setCommunityMenu(community: CommunityModel | undefined) {
    try {
      let menu = [] as any
      const ownerCommunityId = community?.profile?._id ? community?.profile?._id : community?.profile
      if (walletStore.userProfile?._id && walletStore.userProfile?._id == ownerCommunityId) {
        menu.push({
          id: 'announcement',
          title: 'Announcement',
        })
      } else if (community?._id && walletStore.userProfile?._id) {
        const contributors = yield apiService.daos.getContributors(community?._id)
        const contributorIds = map(contributors, '_id')
        if (contributorIds.includes(walletStore.userProfile._id)) {
          menu.push({
            id: 'announcement',
            title: 'Announcement',
          })
        }
      }
      const communityMenu = community?.menu?.list
      if (communityMenu?.length) {
        const formatedCommunityMenu = JSON.parse(JSON.stringify(communityMenu))
        menu = [...menu, ...formatedCommunityMenu]
      }
      this.audienceDetails = [...this.audienceDetails, ...menu]
    } catch (e) {
      console.log('error setCommunityMenu,e')
    }
  }
  @action changePostMode(value) {
    this.isCreatePost = value
  }

  @action changePostTitle(input) {
    this.postTitle = input
  }

  @asyncAction *getAllRelatedDaos() {
    try {
      this.getAllRelatedDaosLoading = true
      this.relatedCommunities = yield apiService.daos.getAllRelatedDaos()
    } catch (error) {
      console.log('getAllRelatedDaos Error: ', error)
    } finally {
      this.getAllRelatedDaosLoading = false
    }
  }

  @action.bound resetInsertOgInfo(blockId) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === blockId)
    if (editorBlockIndex == -1) return
    this.editorBlocks[editorBlockIndex]['insertOgInfo'] = undefined
  }

  @asyncAction *insertUrl(urlLink, blockId) {
    try {
      if (this.linkInserting) return

      const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === blockId)
      if (editorBlockIndex == -1) return

      this.linkInserting = true
      const insertOgInfo = yield this.fetchOpenGraphInfo(urlLink)
      this.editorBlocks[editorBlockIndex]['insertOgInfo'] = insertOgInfo
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.linkInserting = false
    }
  }

  //handle tags
  @asyncAction *fetchTags() {
    const tags = yield apiService.tags.find({})
    this.tagList = tags
    this.tagContentList = tags.map((tag) => tag.content.toLowerCase())
  }
  @action validTagInput(event: any) {
    if (event.key === ' ' || event.key === '#') {
      event.preventDefault()
    }
  }

  @action onTagSelected() {
    this.tagTextSearch = ''
  }
  @action.bound addTagSelected() {
    if (!this.tagTextSearch) {
      return
    }
    this.tagsSelected = [...this.tagsSelected, `#${this.tagTextSearch.toLowerCase()}`]
    this.tagTextSearch = ''
    const tag = this.tagList.filter((tag: TagModel) => tag.content === this.tagTextSearch)
  }

  @asyncAction *createTags(newTags: string[]) {
    try {
      const promises: Promise<any>[] = []
      newTags.map((tag) => {
        promises.push(apiService.tags.create({ content: tag.toLowerCase() }))
      })
      const res = Promise.all(promises)
      return res
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchOpenGraphInfo(url?: string) {
    if (!url) return
    const graphInfo = yield apiService.posts.getOpenGraphInfo(url)
    return graphInfo
  }

  //handle  block
  @asyncAction *initEditorBlocks() {
    this.editorBlockCount = 0
    this.editorBlocks = [
      {
        type: 'text-block',
        id: this.editorBlockCount,
        options: { bounds: `#quill-container-${this.editorBlockCount}` },
        letterCount: 0,
      },
    ]
    this.editorBlockCount += 1
  }

  @action addEditorBlocks(index, media: 'image' | 'video' | 'link') {
    const mediaBlock = {
      type: `${media}-block`,
      id: this.editorBlockCount,
      options: '',
      letterCount: 0,
    }
    const textBlock = {
      type: 'text-block',
      id: this.editorBlockCount + 1,
      options: { bounds: `#quill-container-${this.editorBlockCount + 2}` },
      letterCount: 0,
    }
    this.editorBlocks.splice(index + 1, 0, mediaBlock, textBlock)
    this.editorBlockCount += 2
  }
  @action addTextBlock(index) {
    this.editorBlocks.splice(index, 0, {
      type: 'text-block',
      id: this.editorBlockCount,
      options: { bounds: `#quill-container-${this.editorBlockCount}` },
      letterCount: 0,
    })
    this.editorBlockCount++
  }

  @action addLocationBlock() {
    this.removeLocationBlock()
    this.editorBlocks.splice(this.editorBlocks.length, 0, {
      type: 'location-block',
      id: this.editorBlockCount,
      options: { bounds: `#quill-container-${this.editorBlockCount}` },
      tagLocation: this.tagLocation,
      letterCount: 0,
    })
    this.editorBlockCount++
  }

  @action.bound removeLocationBlock(forceClearData = false) {
    const lastIndex = this.editorBlocks.length - 1
    if (this.editorBlocks[lastIndex].type === 'location-block') this.editorBlocks.splice(lastIndex, 1)
    if (forceClearData) this.tagLocation = undefined
  }

  @action removeEditorBlocks(index, amount = 1) {
    this.editorBlocks.splice(index, amount)
  }

  @action updateTextBlock(id, htmlContent, rawContent) {
    const index = this.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    this.editorBlocks[index]['htmlContent'] = htmlContent
    this.editorBlocks[index]['rawContent'] = rawContent
  }

  @asyncAction *getFileDurationBySecond(fileURL, type) {
    if (type !== 'video') return 0
    const video = document.createElement('video')
    video.src = fileURL
    this.isReadyVideoDuration = false
    video.ondurationchange = () => {
      runInAction(() => {
        this.isReadyVideoDuration = true
      })
    }
    yield when(() => this.isReadyVideoDuration)
    return video?.duration || 0
  }

  @action verifyFileInput() {
    return true
  }

  @asyncAction *changeImageBlockFileInputs(files: File[], id) {
    if (!this.verifyFileInput()) return
    const editorIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorIndex === -1) return

    const verifiedMessage = this.verifyImageFileInput(id, files)
    if (!verifiedMessage.status) {
      snackController.error(verifiedMessage.error)
      this.clearMediaInput(`editor-image-input-${id}`)
      return
    }

    const fileSources: PostMediaSource[] = []
    const pureFiles: PostMediaFile[] = []
    for (let i = 0; i <= files.length - 1; i++) {
      const file = files[i]
      const type = file.type.includes('video') ? 'video' : 'image'
      const fileURL = URL.createObjectURL(file)
      const durationBySecond = yield this.getFileDurationBySecond(fileURL, type)
      const id: any = uuidv4()
      fileSources.push({
        id: id,
        type,
        source: fileURL,
        name: file.name,
        sizeByByte: file.size,
        sizeByMb: Math.round(file.size / MB_TO_BYTE),
        isExceededSize: file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE,
        isStopVideo: true,
        durationBySecond,
      })
      pureFiles.push({ id, file })
    }
    this.editorBlocks[editorIndex]['fileSources'] = chunk(fileSources, 2)
    this.editorBlocks[editorIndex]['pureFiles'] = chunk(pureFiles, 2)
  }

  @action getNormalizedMedias(medias) {
    const normalizedMedias = [] as any
    medias?.map((item) => {
      normalizedMedias[item._id] = {
        url: item.url,
        sizeByByte: item.size ? Math.round(item.size * 1024) : 0,
        sizeByMb: item.size ? Math.round(item.size / 1024) : 0,
      }
    })
    return normalizedMedias
  }

  @action setPostData(post: PostModel) {
    if (post.title) this.postTitle = post.title
    //handle tags
    if (post.tags) this.tagsSelected = map(post.tags, 'content')

    //handle editor block
    const content = post.data?.content
    this.editorBlockCount = 0
    const editorBlocks: EditorBlock[] = []
    const normalizedMedias = this.getNormalizedMedias(post.medias)
    content?.map((block) => {
      const editorBlock: EditorBlock = {
        type: `${block.type}-block`,
        id: this.editorBlockCount,
        letterCount: 0,
      }
      const chuckFileSources = [] as any
      const chuckPureFiles = [] as any
      if (block.type === 'image') {
        block.files.map((chuckFile) => {
          const chuckFileSource: PostMediaSource[] = []
          const chuckPureFile: PostMediaFile[] = []
          chuckFile.map((fileId) => {
            const file = normalizedMedias[fileId]
            chuckFileSource.push({
              id: fileId,
              type: block.type,
              source: file.url,
              sizeByByte: file.size,
              sizeByMb: file.sizeByMb,
              isExceededSize: false,
              isStopVideo: true,
              isEditMediaSource: true,
            })
            chuckPureFile.push({
              id: fileId,
              isEditMediaFile: true,
            })
          })
          chuckFileSources.push(chuckFileSource)
          chuckPureFiles.push(chuckPureFile)
        })
        editorBlock.fileSources = chuckFileSources
        editorBlock.pureFiles = chuckPureFiles
      } else if (block.type === 'video') {
        const fileId = block.files[0]
        const file = normalizedMedias[fileId]
        const fileSource: PostMediaSource = {
          id: fileId,
          type: block.type,
          source: file.url,
          sizeByByte: file.size,
          sizeByMb: file.sizeByMb,
          isExceededSize: false,
          isStopVideo: true,
          isEditMediaSource: true,
        }
        const pureFile: PostMediaFile = { id: fileId, isEditMediaFile: true }
        editorBlock.fileSource = fileSource
        editorBlock.pureFile = pureFile
      } else if (block.type === 'link') {
        editorBlock.insertOgInfo = {}
        editorBlock.link = block?.link
      } else {
        editorBlock.htmlContent = block.htmlContent
        editorBlock.options = { bounds: `#quill-container-${this.editorBlockCount}` }
        editorBlock.letterCount = formatStringNoExtraSpace(block.rawContent || '').length
      }
      editorBlocks.push(editorBlock)
      this.editorBlockCount++
    })
    this.editorBlocks = editorBlocks

    if (this.tagLocation) {
      const locationBlock = {
        type: 'location-block',
        id: this.editorBlockCount,
        options: { bounds: `#quill-container-${this.editorBlockCount}` },
        tagLocation: this.tagLocation,
        letterCount: 0,
      }
      this.editorBlockCount++
      this.editorBlocks = [...this.editorBlocks, locationBlock]
    }

    if (!post.poll)
      //handle poll
      this.isEnableEditPoll = true
    else {
      const pollVotes = [] as any
      post.poll?.data?.options?.map((item, index) => {
        pollVotes.push({ id: item.index, label: `Option ${index + 1}`, textInput: item.title, error: '' })
      })
      this.pollVotes = pollVotes
      this.isPollSelected = true
      this.pollDurationByDay = post.poll?.data?.durationTime?.days
      this.pollDurationByHour = post.poll?.data?.durationTime?.hours
      this.pollDurationByMinute = post.poll?.data?.durationTime?.minutes

      this.isEnableEditPoll = this.type === 'draft' ? true : false
    }
  }

  @action verifyVideoFileInput(id, file) {
    let message = { status: true, error: '' } as any
    const checkEditorBlocks = this.editorBlocks.filter((item) => item.id !== id && item.type === 'video-block')
    if (some(checkEditorBlocks, (videoBlock) => videoBlock.pureFile?.id))
      message = {
        status: false,
        error: 'Up to one video can only be uploaded',
      }
    else if (file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE)
      message = {
        status: false,
        error: `Video must not exceed ${MAX_SIZE_IMAGE_UPLOAD_BY_MB}MB`,
      }

    return message
  }
  @action verifyImageFileInput(id, files: File[]) {
    let message = { status: true, error: '' } as any

    const checkEditorBlocks = this.editorBlocks.filter(
      (item) => item.type === 'image-block' && item.id !== id && item.fileSources?.length
    )
    const checkFileSources = flattenDeep(map(checkEditorBlocks, 'fileSources'))
    const previousImagesTotalSize = sumBy(checkFileSources, (item) => item?.sizeByByte || 0)
    const currentImagesTotalSize = sumBy(files, (item) => item.size)
    const totalSize = previousImagesTotalSize + currentImagesTotalSize
    if (totalSize > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE)
      message = {
        status: false,
        error: `Uploaded photos must not exceed ${MAX_SIZE_IMAGE_UPLOAD_BY_MB}MB`,
      }
    return message
  }

  @asyncAction *changeVideoBlockFileInput(file: File, id) {
    if (!this.verifyFileInput()) return
    const editorIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorIndex === -1) return

    const verifiedMessage = this.verifyVideoFileInput(id, file)
    if (!verifiedMessage.status) {
      snackController.error(verifiedMessage.error)
      this.clearMediaInput(`editor-video-input-${id}`)
      return
    }

    const type = file.type.includes('video') ? 'video' : 'image'
    const fileURL = URL.createObjectURL(file)
    const durationBySecond = yield this.getFileDurationBySecond(fileURL, type)
    const mediaId: any = uuidv4()
    const fileSource = {
      id: mediaId,
      type,
      source: fileURL,
      name: file.name,
      sizeByByte: file.size,
      sizeByMb: Math.round(file.size / MB_TO_BYTE),
      isExceededSize: file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE,
      isStopVideo: true,
      durationBySecond,
    } as PostMediaSource
    const pureFile = { id: mediaId, file } as PostMediaFile
    this.editorBlocks[editorIndex]['fileSource'] = fileSource
    this.editorBlocks[editorIndex]['pureFile'] = pureFile
  }

  @action clearMediaInput(id) {
    const dt = new DataTransfer() as any
    const mediaInput = document.getElementById(id) as any
    mediaInput.files = dt.files
  }

  @action removeImageBlockFileInput(id, row, itemIndex) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    const pureFileRow = this.editorBlocks[editorBlockIndex].pureFiles[row]
    const sourceFileRow = this.editorBlocks[editorBlockIndex].fileSources[row]
    pureFileRow.splice(itemIndex, 1)
    sourceFileRow.splice(itemIndex, 1)
    if (pureFileRow.length) {
      this.editorBlocks[editorBlockIndex].pureFiles[row] = pureFileRow
      this.editorBlocks[editorBlockIndex].fileSources[row] = sourceFileRow
    } else {
      this.editorBlocks[editorBlockIndex].pureFiles.splice(row, 1)
      this.editorBlocks[editorBlockIndex].fileSources.splice(row, 1)
    }
    this.clearMediaInput(`editor-image-input-${id}`)
  }

  @action removeVideoBlockFileInput(id) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    this.editorBlocks[editorBlockIndex].pureFile = {}
    this.editorBlocks[editorBlockIndex].fileSource = {}
    this.clearMediaInput(`editor-video-input-${id}`)
  }

  // control playing and pausing video
  @action changeVideoControls(id, value) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    if (this.editorBlocks[editorBlockIndex].fileSource)
      this.editorBlocks[editorBlockIndex].fileSource.isStopVideo = value
  }

  // handle poll
  @action addPollOption() {
    this.pollCount++
    this.pollVotes.push({ id: this.pollCount, label: `Option ${this.pollVotes.length + 1}`, textInput: '', error: '' })
  }

  removePollOption(id) {
    if (this.pollVotes.length <= 2) return
    this.pollVotes = this.pollVotes.filter((item) => item.id !== id)
  }

  @action changePollStatus(value) {
    this.isPollSelected = value
  }

  @action validPoll() {
    this.pollVotes.forEach((item) => {
      if (!item.textInput || item.textInput.trim().length === 0) {
        item.error = 'Option is empty'
      }
    })
  }

  @action changePollDurationByDay(value) {
    this.pollDurationByDay = value
    this.pollDurationByDayError = ''
  }
  @action changePollDurationByHour(value) {
    this.pollDurationByHour = value
    this.pollDurationByHourError = ''
  }
  @action changePollDurationByMinute(value) {
    this.pollDurationByMinute = value
    this.pollDurationByMinuteError = ''
  }

  @action validPollDuration() {
    if (!this.pollDurationByDay || !numberHelper.isPositiveInterger(this.pollDurationByDay))
      this.pollDurationByDayError = 'Invalid field'
    if (!this.pollDurationByHour || !numberHelper.isPositiveInterger(this.pollDurationByHour))
      this.pollDurationByHourError = 'Invalid field'
    if (!this.pollDurationByMinute || !numberHelper.isPositiveInterger(this.pollDurationByMinute))
      this.pollDurationByMinuteError = 'Invalid field'
  }

  @action getPollDuration() {
    return moment.duration({
      days: +this.pollDurationByDay,
      hours: +this.pollDurationByHour,
      minutes: +this.pollDurationByMinute,
    })
  }

  @action changeVoteInput(id, value) {
    const index = this.pollVotes.findIndex((item) => item.id === id)
    if (index === -1) return
    const pollVotes = [...this.pollVotes]
    pollVotes[index].textInput = value
    pollVotes[index].error = ''
    this.pollVotes = pollVotes
  }

  @asyncAction *uploadMediaFiles() {
    const imageEditors = this.editorBlocks.filter((item) => item.type === 'image-block' && item?.pureFiles?.length)
    const videoEditors = this.editorBlocks.filter((item) => item.type === 'video-block' && item?.pureFile)
    if (videoEditors.length > 1) snackController.error('Invalid video')
    const videoFile = videoEditors[0]?.pureFile?.file
    const videoId = videoEditors[0]?.pureFile?.id
    const imagePureFiles = map(imageEditors, 'pureFiles')

    const flattenImagePureFiles = flattenDeep(imagePureFiles).filter((item) => !item.isEditMediaFile)
    const imageFiles = map(flattenImagePureFiles, 'file')
    const imageIds = map(flattenImagePureFiles, 'id')
    const mediaFiles = imageFiles
    const mediaIds = imageIds
    if (videoFile && !videoEditors[0]?.pureFile.isEditMediaFile) {
      mediaFiles.push(videoFile)
      mediaIds.push(videoId)
    }
    const promises: Promise<any>[] = []
    for (let i = 0; i < mediaFiles.length; i++) {
      const uploadPromise = apiService.posts.upload(mediaFiles[i])
      promises.push(uploadPromise)
    }
    const res = yield Promise.all(promises)
    const mediaIdsRes = map(flattenDeep(res), 'id')
    const mediasRes = []
    for (let i = 0; i < mediaIdsRes.length; i++) {
      mediasRes[`${mediaIds[i]}`] = mediaIdsRes[i]
    }
    if (this.isEditPost) {
      if (videoId && videoEditors[0]?.pureFile.isEditMediaFile) {
        mediasRes[`${videoId}`] = videoId
      }
      const uploadedImagePureFiles = flattenDeep(imagePureFiles).filter((item) => item.isEditMediaFile)
      uploadedImagePureFiles?.map((pureFile) => {
        mediasRes[`${pureFile.id}`] = pureFile.id
      })
    }
    return mediasRes
  }

  @action changeTextBlock(id, text) {
    this.isValidLetterCount = true
    const index = this.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    const validText = formatStringNoExtraSpace(text || '')
    this.editorBlocks[index].letterCount = validText.length
  }

  @asyncAction *buildDataBlockPayload() {
    const uploadedMediaIds = yield this.uploadMediaFiles()
    const uploadedLocationImageId = yield this.uploadLocationImage()
    const blockPayload = [] as any
    this.editorBlocks.forEach((block, index) => {
      if (block.type === 'image-block') {
        const pureFiles = block?.pureFiles
        if (pureFiles?.length) {
          const uploadedMedias = [] as any
          pureFiles.forEach((rowFiles) => {
            const filesByRow = [] as any
            rowFiles.forEach((file) => {
              filesByRow.push(uploadedMediaIds[file.id])
            })
            uploadedMedias.push(filesByRow)
          })

          blockPayload.push({
            type: 'image',
            files: uploadedMedias,
          })
        }
      } else if (block.type === 'video-block') {
        const pureFileId = block?.pureFile?.id
        if (pureFileId) {
          blockPayload.push({
            type: 'video',
            files: [uploadedMediaIds[pureFileId]],
          })
        }
      } else if (block.type === 'text-block') {
        if (!(index === this.editorBlocks.length - 1 && block.rawContent === '\n'))
          blockPayload.push({
            type: 'text',
            htmlContent: block.htmlContent,
            rawContent: block.rawContent,
          })
      } else if (block.type === 'link-block' && block.insertOgInfo?.requestUrl) {
        blockPayload.push({
          type: 'link',
          link: block.insertOgInfo.requestUrl,
        })
      }
    })
    return {
      blockPayload,
      medias: Object.values(uploadedMediaIds),
      uploadedLocationImageId,
    }
  }

  @asyncAction *uploadLocationImage() {
    const mapLocationId = this.selectedPost?.mapLocation?.id
    if (this.tagLocation && mapLocationId && !this.tagLocation?.base64Source) return mapLocationId
    if (!this.tagLocation?.base64Source) return undefined
    const blob = b64toBlob(this.tagLocation.base64Source, 'image/png')
    const res = yield apiService.posts.upload(blob)
    return res[0]?._id
  }

  @action buildSomeFieldRelateAudience() {
    let postParams = {}
    // type
    postParams = { ...postParams, type: this.audience }
    switch (this.audience) {
      case 'review':
        postParams = { ...postParams, reviewCategories: this.selectedReviewCategories, reviewRating: this.reviewRating }
        break
      case 'my-dao':
        postParams = { ...postParams, daoMenu: this.audienceDetail, daoId: this.selectedDao?._id }
        break
    }
    return postParams
  }

  @asyncAction *getPayload(dataBlockPayload = {} as any) {
    let postParams = {
      title: this.postTitle,
      medias: [],
    } as any
    if (dataBlockPayload?.medias?.length) {
      postParams = { ...postParams, medias: dataBlockPayload.medias }
    }
    postParams = { ...postParams, data: { content: dataBlockPayload.blockPayload } }
    if (this.communityId) postParams = { ...postParams, daoId: this.communityId }
    //tags
    if (this.tagsSelected?.length) {
      const tagsExisted: TagModel[] = []
      const newTags: string[] = []
      this.tagsSelected.map((item) => {
        const tag = this.tagList.find((tag) => tag.content?.toLowerCase() === item.toLowerCase())
        if (tag) tagsExisted.push(tag)
        else newTags.push(item.toLowerCase())
      })
      const newTagsCreated = yield this.createTags(newTags)
      const existedTags = toJS(tagsExisted)
      const tags = [...existedTags, ...newTagsCreated]
      postParams = { ...postParams, tags }
    }
    postParams = { ...postParams, status: this.isCreatePost ? 'public' : 'draft' }
    if (this.selectedPost?.poll && this.isEnableEditPoll && !this.isPollSelected) {
      postParams = { ...postParams, poll: null }
      return postParams
    }
    // location
    const mapLocation = dataBlockPayload.uploadedLocationImageId
    postParams = { ...postParams, mapLocation: mapLocation || null, location: this.tagLocation?.location || null }
    postParams = { ...postParams, ...this.buildSomeFieldRelateAudience() }

    // repost
    if (this.repostedPost) {
      postParams = { ...postParams, rePost: this.repostedPost?.id }
    }

    //poll
    if (!this.isPollSelected || !this.isEnableEditPoll) return postParams
    const pollOptions = [] as any
    this.pollVotes.map((item, index) => {
      pollOptions.push({ index, title: item.textInput })
    })
    const pollEndDate = moment().add(this.getPollDuration())
    const poll = {
      endDate: pollEndDate,
      data: {
        options: pollOptions,
        durationTime: {
          days: +this.pollDurationByDay,
          hours: +this.pollDurationByHour,
          minutes: +this.pollDurationByMinute,
        },
        startTime: moment().toISOString(),
      },
    }
    postParams = { ...postParams, poll }
    return postParams
  }

  @action validateInput() {
    this.validPoll()
    if (!this.isValidVotes) this.isValidInput = false
    this.validPollDuration()
    if (!this.isValidPollDuration) this.isValidInput = false

    if (this.getPollDuration().asSeconds() == 0) {
      // snackController.error('Please enter the correct poll length')
      this.isValidInput = false
    }
  }

  @action validateTextBlocks() {
    if (!this.audience) return
    if (this.getSumLetterTextBlocks < this.validLetterCount) {
      this.isValidLetterCount = false
      this.isValidInput = false
    }
  }

  @action changeCompletedUpdateType(value) {
    this.completedUpdateType = value
  }

  @asyncAction *createPost() {
    try {
      this.isValidInput = true
      if (this.isEnableEditPoll && this.isPollSelected) {
        this.validateInput()
      }
      this.verifyPostType()
      this.validateTextBlocks()
      if (!this.isValidInput) return
      this.createPostLoading = true
      const dataBlockPayload = yield this.buildDataBlockPayload()
      if (!dataBlockPayload.blockPayload.length) {
        snackController.error('Content cannot be empty')
        return
      }
      const payload = yield this.getPayload(dataBlockPayload)
      if (this.isEditPost) {
        yield apiService.posts.updatePost({ id: this.postId, ...payload })
        this.completedUpdateType = this.type
        if (this.type === 'draft')
          snackController.success(this.isCreatePost ? 'Your post have published' : 'Update the draft post completed')
        else snackController.success('Update the post completed')
      } else {
        yield apiService.posts.createPost(payload)
        this.completedUpdateType = 'create'
        snackController.success('Post upload completed')
      }
      this.show(false)
      return true
    } catch (error) {
      snackController.commonError(error)
      return false
    } finally {
      this.createPostLoading = false
    }
  }

  @computed get itemsDisplayed() {
    if (!this.tagTextSearch) {
      return this.tagsSelected
    }
    const response = this.tagContentList.filter((e) => e.includes(this.tagTextSearch.toLowerCase()))
    return this.tagsSelected.concat(response)
  }

  @computed get isValidVotes() {
    return !some(this.pollVotes, (vote) => {
      return !!vote.error
    })
  }
  @computed get isValidPollDuration() {
    return !this.pollDurationByDayError && !this.pollDurationByHourError && !this.pollDurationByMinuteError
  }
  @computed get isCommunityOwner() {
    return this.community && this.community?.profile?._id === walletStore.userProfile?._id
  }

  // handle display repost info
  @computed get repostedPost() {
    return this.repostedPostStore?.post
  }
  @computed get firstMediaRepost() {
    return this.repostedPostStore?.firstMedia
  }

  @computed get firstTextBlockRepost() {
    return this.repostedPostStore?.firstTextBlock
  }

  @computed get repostTitle() {
    return this.repostedPostStore?.title
  }
  @computed get repostProfile() {
    return this.repostedPost?.profile
  }

  @computed get repostCreatedAt() {
    return this.repostedPost?.createdAt
  }

  @computed get firstMediaRepostIsImage() {
    return this.firstMediaRepost?.mime?.includes('image')
  }

  @computed get firstMediaRepostIsVideo() {
    return this.firstMediaRepost?.mime?.includes('video')
  }
  @computed get getSumLetterTextBlocks() {
    return sumBy(this.editorBlocks, (item) => item.letterCount || 0)
  }
  @computed get validLetterCount() {
    let count = 0
    switch (this.audience) {
      case 'review':
        count = 50
        break
      default:
        count = 10
        break
    }
    return count
  }
  @computed get showRemindAtleastLetterCount() {
    return (
      createPostController.audience &&
      createPostController.getSumLetterTextBlocks < createPostController.validLetterCount
    )
  }
}

export const createPostController = new CreatePostController()
