import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export interface SignUpData {
  publicAddress: string
}

export interface SignInSignature {
  signedTxn: string
  publicAddress: string
  nonce: number
}

export class ApiHandlerJWT<T> {
  constructor(private axios: any, private route: ApiRouteType) { }

  // async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
  //   const settingDefault = { _sort: 'createdAt:DESC', _limit: 25, _start: 0 }
  //   params = { ...settingDefault, ...settings, ...(params ?? {}) }
  //   const res = await this.axios.get(this.route, { params })
  //   const lst = res.data
  //   return lst
  // }

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async depositTicket(model: T): Promise<T> {
    const { investorId, poolId, amount, applyId, depositedTicketNumber } = model as any
    const res = await this.axios.post(
      'tickets/createTicket',
      { investorId, poolId, amount, applyId, depositedTicketNumber },
      {
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      }
    )
    return res.data
  }

  async delete(id: any): Promise<T> {
    const res = await this.axios.delete(`${this.route}/${id}`, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        // Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    const lst = res.data
    return lst
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    } else {
      res = await this.axios.get(`${this.route}`, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    }
    const result = res.data
    return result
  }

  async update(id: any, model?: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.put(`${this.route}/${id}`, model, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    } else {
      res = await this.axios.put(`${this.route}`, model, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    }
    return res.data
  }

  async signUp(signupData: SignUpData) {
    const res = await this.axios.post(`auth/local/register`, signupData)
    return res.data
  }

  async signIn(signature: SignInSignature) {
    const res = await this.axios.post(`auth/signin`, signature)
    return res.data
  }

  async socialSignIn(provider: string, accessToken: string) {
    const res = await this.axios.get(`auth/${provider}/callback?access_token=${accessToken}`)
    return res.data
  }

  async getMe() {
    const res = await this.axios.get(`users/me`, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async connectSocial(provider: string, accessToken: string, accessSecret?: string) {
    const urlQuery = accessSecret
      ? `auth/${provider}/callback?access_token=${accessToken}&access_secret=${accessSecret}`
      : `auth/${provider}/callback?access_token=${accessToken}`
    const res = await this.axios.get(urlQuery)
    return res.data
  }

  async createSession(model) {
    const { investorId } = model
    const res = await this.axios.post(
      `investors/create-session`,
      { investorId },
      { headers: { Authorization: `Bearer ${localData.getAccessToken()}` } }
    )
    return res.data
  }
  async createApply(model) {
    const res = await this.axios.post(`applies/create-apply`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
  async applyStakingTickets(model) {
    const res = await this.axios.post(`applies/apply-staking-tickets`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async checkKycAddresss(params) {
    const res = await this.axios.post(`investors/check-kyc-address`, params, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async searchForum<T>(text: string): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/search/forum?text=${text}`, { params: {}, headers })
    return res.data
  }
}
