import { PoolModel } from '@/models/pool-model'
import { InvestorModel } from '@/models/investor-model'
import { MintPhaseHandler } from './handlers/minPhaseHandler'
import { ApiHandler } from './handlers/apiHandler'
import { ApiHandlerJWT } from './handlers/apiHandlerJWT'
import { PostHandler } from './handlers/postHandler'
import { VaultHandler } from './handlers/vaultHandler'
import { CommentHandler } from './handlers/commentHandler'
import { TagFollowHandler } from './handlers/tagFollowHandler'
import { UserFollowHandler } from './handlers/userFollowHandler'
import { ReportHandler } from './handlers/reportHandler'
import { BlockHandler } from './handlers/blockHandler'
import { axios } from './axios-config'
import { ProfileHandler } from './handlers/profileHandler'
import { LikeHandler } from './handlers/likeHandler'
import { NotificationHandler } from './handlers/notificationHandler'
import { VoteHandler } from './handlers/voteHandler'
import { MarketplaceItemHandler } from './handlers/marketplaceItemHandler'
import { BookmarkHandler } from './handlers/bookmarkHandler'
import { DaoCategoryHandler } from './handlers/daoCategoryHandler'
import { DaoHandler } from './handlers/daoHandler'
import { LinkHandler } from './handlers/linkHandler'
import { PollHandler } from './handlers/pollHandler'
import { UserDaoHandler } from './handlers/userDaoHandler'
import { DaoRequestHandler } from './handlers/daoRequestHandler'
import { InvitationLinkHandler } from './handlers/invitationLinkHandler'
import { ReviewCategoryHandler } from './handlers/reviewCategoryHandler'
import { MintWhitelistApplyHandler } from './handlers/mintWhitelistApplyHandler'
import { EarningActionHandler } from './handlers/earningActionHandler'

export type VaultApiRoute = 'notes' | 'mint-phases' | 'mint-transactions' | 'pens'

// axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     get(error, 'response.status') === 401
//     // return Promise.reject(error)
//     walletStore.setShowRequireLoginDialog(true)
//   }
// )
export class ApiService {
  pools = new ApiHandler<PoolModel>(axios, 'pools')
  users = new ApiHandlerJWT<any>(axios, 'users')
  profiles = new ProfileHandler<any>(axios, 'profiles')
  applies = new ApiHandlerJWT<any>(axios, 'applies')
  investors = new ApiHandlerJWT<InvestorModel>(axios, 'investors')
  logs = new ApiHandlerJWT<any>(axios, 'logs')
  tickets = new ApiHandlerJWT<any>(axios, 'tickets')
  stakingStatistics = new ApiHandler<any>(axios, 'staking-statistics')
  noteNfts = new VaultHandler<any>(axios, 'notes')
  mintingPhases = new MintPhaseHandler<any>(axios, 'mint-phases')
  mintTransaction = new VaultHandler<any>(axios, 'mint-transactions')
  posts = new PostHandler<any>(axios, 'posts')
  comments = new CommentHandler<any>(axios, 'comments')
  tags = new ApiHandlerJWT<any>(axios, 'tags')
  likes = new LikeHandler<any>(axios, 'likes')
  tagFollows = new TagFollowHandler<any>(axios, 'tag-followings')
  userFollows = new UserFollowHandler<any>(axios, 'user-followings')
  marketplaceItems = new MarketplaceItemHandler<any>(axios, 'marketplace-items')
  penNfts = new VaultHandler<any>(axios, 'pens')
  reports = new ReportHandler<any>(axios, 'reports')
  blocks = new BlockHandler<any>(axios, 'blocks')
  notifications = new NotificationHandler<any>(axios, 'notifications')
  votes = new VoteHandler<any>(axios, 'votes')
  bookmarks = new BookmarkHandler<any>(axios, 'bookmarks')
  links = new LinkHandler<any>(axios, 'links')
  daos = new DaoHandler<any>(axios, 'daos')
  polls = new PollHandler<any>(axios, 'polls')
  userDao = new UserDaoHandler<any>(axios, 'user-daos')
  daoCategoryHandler = new DaoCategoryHandler<any>(axios, 'dao-categories')
  daoRequest = new DaoRequestHandler<any>(axios, 'dao-requests')
  inviteLink = new InvitationLinkHandler<any>(axios, 'invitation-links')
  reviewCategories = new ReviewCategoryHandler<any>(axios, 'review-categories')
  mintWhitelistApplies = new MintWhitelistApplyHandler(axios, 'mint-whitelist-applies')
  earningActions = new EarningActionHandler(axios, 'earning-actions')
  apiHandler = new ApiHandler<any>(axios, 'users')
}

export const apiService = new ApiService()
