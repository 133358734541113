


















import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { AppProvider, appProvider } from './app-provider'
import { createPostController } from './modules/common/dialogs/create-post/create-post-controller'
// import { walletStore } from './stores/wallet-store'

@Component({
  components: {
    'app-bar': () => import('@/components/appbar/app-bar.vue'),
    'navigation-bar': () => import('./components/navbar/navigation-bar.vue'),
    drawer: () => import('./components/navbar/drawer.vue'),
    'global-loading': () => import('@/components/global-loading/global-loading.vue'),
    'snack-bar': () => import('@/components/snack-bar/snack-bar.vue'),
    alert: () => import('@/components/alert/alert.vue'),
  },
})
export default class App extends Vue {
  @Provide() providers: AppProvider = appProvider
  createPostController = createPostController

  get lastPath() {
    const paths = this.$route.fullPath.split('/')
    return paths[paths.length - 1]
  }

  get hideNavbar() {
    if (
      (this.lastPath === 'summary' || this.lastPath === 'posts' || this.lastPath === 'account') &&
      this.$vuetify.breakpoint.width < 1280
    ) {
      return true
    }

    if (this.lastPath.includes('forum') && this.$vuetify.breakpoint.width < 737) {
      return true
    }

    if (this.$route.name === 'Community Detail' && this.$vuetify.breakpoint.width < 1280) {
      return true
    }

    return false
  }

  get isMobile() {
    return this.$vuetify.breakpoint.width < 1280
  }

  drawerDialog = false

  @Watch('drawerDialog', { immediate: true }) changeDrawerDialog(value) {
    this.providers.changeDrawerDialog(value)
  }

  mounted() {
    this.providers.router = this.$router
    // walletStore.start()
  }

  goBack() {
    history.back()
  }

  get showNav() {
    const appbar = this.$route.meta && this.$route.meta.appbar
    if (appbar === false) {
      return false
    } else {
      return true
    }
  }
}
