import { action, autorun, computed, observable, reaction, runInAction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { localData } from './local-data'
import algosdk, { Algodv2 } from 'algosdk'
import { apiService } from '@/services/api-services'
import { InvestorModel } from '@/models/investor-model'
import SynapsClient from '@synaps-io/verify.js'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { encode, decode } from 'uint8-to-base64'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from 'algorand-walletconnect-qrcode-modal'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import { UserModel } from '@/models/user-model'
import { appProvider } from '@/app-provider'
import { checkDeviceType } from '@/helper/ua-parser'
import { algoClient } from '@/algo-client'
import { ProfileModel } from '@/models/profile-model'
import moment from 'moment'
import { cloneDeep, get } from 'lodash'
import router from '@/router'
import { deepCopy } from 'ethers/lib/utils'
import { accountSettingController } from '@/components/auth/account-setting-controller'
import { walletSettingController } from '@/components/auth/wallet-setting-controller'
import { promiseHelper } from '@/helper/promise-helper'

export const connector = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org',
  qrcodeModal: QRCodeModal,
})
export class WalletStore {
  @observable detailDialog = false
  @observable connectDialog = false

  @observable account = localData.getActiveWalletAddress()
  @observable selectedWallet = ''
  @observable showConnectDialog = false
  @observable chainId = 4160
  @observable userProfile?: ProfileModel = undefined
  @observable jwt = localData.getAccessToken()
  algodClient?: Algodv2 = undefined
  @observable loadingKycState = false
  @observable sessionId: any = ''
  @observable isLoadingSignIn = false
  @observable userInfo?: UserModel = undefined
  @observable investor?: InvestorModel = undefined
  @observable loading = false
  @observable connectingWallet = false
  @observable synapsClient: any = undefined
  @observable showRequireLoginDialog = false
  @observable notInWhitelist = false
  @observable loaded = false
  @observable recommendUsers: ProfileModel[] = []
  @observable isNewBie = false
  @observable isFirstLogin = false

  constructor() {
    // this.algodClient = algoClient

    // autorun(() => {
    //   this.init(this.account, this.jwt)
    // })
  }

  @asyncAction *init(account, jwt) {
    if (!account && !jwt) {
      this.loaded = true
      return
    }
    const hasUser = yield this.checkUserWallet()
    if (jwt) {
      this.isFirstLogin = !!localData.getFirstLoginState()
      this.checkNewBie()
      this.getUserFollowRecommends()
    }
    this.loaded = true
    if (account && !hasUser) {
      this.signUpWallet(account)
    }
    if (jwt && !this.userProfile) {
      yield promiseHelper.delay(1000)
      if (account) {
        accountSettingController.setOpenDialog(true)
      } else {
        walletSettingController.setOpenDialog(true)
      }
    }
  }

  @action checkNewBie() {
    if (this.userProfile) {
      const newBieTime = moment(this.userProfile.createdAt).add(3, 'days')
      this.isNewBie = moment().isBefore(newBieTime)
    }
  }

  @action changeUserProfile(obj) {
    this.userProfile = { ...this.userProfile, ...obj }
  }

  @action changeFirstLoginState(value) {
    localData.setFirstLoginState(value)
    this.isFirstLogin = value
  }

  @action.bound showDetailWalletDialog(value: boolean) {
    this.detailDialog = value
  }

  @action.bound showConnectWalletDialog(value: boolean) {
    this.connectDialog = value
  }

  async test() {
    // await this.blockChainHandler?.sendOptIn(this.account)
    // await this.blockChainHandler?.buy(this.account, 100000000)
  }

  @asyncAction *getUserFollowRecommends() {
    try {
      if (this.userProfile) {
        const res = yield apiService.userFollows.getUserFollowRecommends({})
        this.recommendUsers = res.map((item) => ({ ...item, value: item.username }))
      }
    } catch (e) {
      console.log('error getUserFollowRecommends', e)
    }
  }

  @action resetCache() {
    this.jwt = ''
    this.userInfo = undefined
    this.investor = undefined
  }

  @asyncAction *start() {
    try {
      if (localData.getActiveWalletAddress) this.account = localData.getActiveWalletAddress()
      if (localData.getWalletSelected) this.selectedWallet = localData.getWalletSelected()
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound changeShowConnectDialog(value: boolean) {
    this.showConnectDialog = value
  }

  @action.bound setShowRequireLoginDialog(value: boolean) {
    this.showRequireLoginDialog = value
  }

  @action.bound setNotInWhitelistDialog(value: boolean) {
    this.notInWhitelist = value
  }

  @asyncAction *connectMyAlgoWallet() {
    this.connectingWallet = true
    try {
      const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
      const settings = {
        shouldSelectOneAccount: true,
        openManager: true,
      }
      const accounts = yield myAlgoConnect.connect(settings)
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'my-algo-wallet'
        localData.setWalletSelected('my-algo-wallet')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
      this.signIn(false)
    } catch (e) {
      //
    } finally {
      this.connectingWallet = false
    }
  }

  @asyncAction *connectAlgoSigner() {
    if (typeof (window as any).AlgoSigner !== 'undefined') {
      yield (window as any).AlgoSigner.connect()
      const accounts = yield (window as any).AlgoSigner.accounts({
        ledger: 'TestNet',
      })
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'algo-signer'
        localData.setWalletSelected('algo-signer')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
      this.signIn()
    } else {
      ; (window as any)
        .open('https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm', '_blank')
        .focus()
    }
  }

  @asyncAction *createSynapsSession() {
    try {
      if (!this.sessionId) {
        this.loadingKycState = true
        const res: any = yield apiService.applies.createSession({ investorId: this.investor?.id })
        this.sessionId = res?.synapsSessionId
        if (!this.synapsClient) {
          this.synapsClient = new SynapsClient(this.sessionId, 'individual')
          this.synapsClient.init()
          this.synapsClient.on('finish', async () => {
            this.investor = await apiService.investors.findOne(this.investor?.id)
          })
        }
      }
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loadingKycState = false
    }
  }

  @asyncAction *connectViaWalletConnect() {
    try {
      if (!connector.connected) {
        connector.createSession()
      }
      if (connector.pending) {
        QRCodeModal.open(connector.uri, null)
      }
      connector.on('connect', (error, payload) => {
        runInAction(() => {
          this.account = payload?.params[0].accounts[0]
          this.selectedWallet = 'pera-wallet'
          localData.setActiveWalletAddress(this.account)
          this.showConnectWalletDialog(false)
          QRCodeModal.close()
          this.signIn(false)
        })
        if (error) {
          throw error
        }
      })

      connector.on('session_update', (error, payload) => {
        if (error) {
          throw error
        }
      })

      connector.on('disconnect', (error, payload) => {
        if (error) {
          throw error
        }
        if (payload.params[0]?.message === 'Session update rejected') snackController.error('User reject request')
        else this.disconnect()
      })
    } catch (error) {
      snackController.commonError(error)
    } finally {
      //
    }
  }

  @asyncAction *signMessage(showError = true) {
    try {
      if (!this.account) return {}
      const user = yield apiService.users.find({ username: this.account })
      const nonce = user[0]?.nonce
      const message = `https://algolaunch.io wants to: \n Sign message with account \n ${this.account} - One time nonce: ${nonce}`
      const encoder = new TextEncoder()
      const messageEncoded = encoder.encode(message)

      const params = yield this.algodClient?.getTransactionParams().do()
      const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: this.account,
        to: this.account,
        amount: 0,
        note: messageEncoded,
      })

      if (this.selectedWallet === 'algo-signer') {
        const txn_b64 = (window as any).AlgoSigner.encoding.msgpackToBase64(txn.toByte())
        const signedTxs = yield (window as any).AlgoSigner.signTxn([{ txn: txn_b64 }])
        return { signedTxn: signedTxs[0].blob, publicAddress: this.account }
      } else if (this.selectedWallet === 'my-algo-wallet') {
        const myAlgoConnect = new MyAlgoConnect()
        const signedTxns = yield myAlgoConnect.signTransaction(txn.toByte())
        const signedTxn = signedTxns.blob
        const signedTxnBase64 = encode(signedTxn)
        return { signedTxn: signedTxnBase64, publicAddress: this.account, nonce }
      } else if (this.selectedWallet === 'pera-wallet') {
        const txns = [txn]
        const txnsToSign = txns.map((txn) => {
          const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
          return {
            txn: encodedTxn,
          }
        })
        const requestParams = [txnsToSign]
        const request = formatJsonRpcRequest('algo_signTxn', requestParams)
        const deviceType = checkDeviceType()
        //Request open Pera Wallet application on mobile browser
        if (deviceType?.device?.type === 'mobile') {
          const deepLink = deviceType?.os?.name === 'iOS' ? 'algorand-wc://' : 'algorand://'
          window.location.href = deepLink
        }
        const result: Array<string | null> = yield connector.sendCustomRequest(request)
        const decodedResult = result.map((element) => {
          return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
        })
        const signedTxn = encode(decodedResult[0])
        if (!signedTxn) {
          this.disconnect()
          snackController.error('Have sign message error. Please try again!')
        }
        return { signedTxn: signedTxn, publicAddress: this.account, nonce }
      }
    } catch (error: any) {
      showError && snackController.commonError(error)
    }
  }

  @asyncAction *connectSocialAccount(provider: string, accessToken: string) {
    try {
      const res = yield apiService.users.socialSignIn(provider, accessToken)
      this.jwt = res.jwt

      // fix mobx issue => not change this

      localData.setFirstLoginState(res.user?.profile?.totalPosts === 0 ? true : false)
      this.isFirstLogin = !!localData.getFirstLoginState()

      if (res.user) {
        this.userInfo = { ...res.user }
      }
      if (res.user?.investor) {
        this.investor = { ...res.user?.investor }
      }

      if (res.user?.profile) {
        this.userProfile = { ...res.user?.profile }
      }
      //end
      localData.setAccessToken(res.jwt)
      localData.setLastSeen(moment().format('MMM,DD, YYYY'))
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *updateUserProfile(params) {
    const profile = yield apiService.profiles.updateUserProfile(params)
    this.userProfile = profile
    return profile
  }

  @asyncAction *createUserProfile(params: {
    email?: string,
    userName?: string,
    walletAddress?: string,
    signatureTx?: string
  }) {
    const { email, userName, signatureTx, walletAddress } = params
    try {
      this.loading = true
      const signature = signatureTx || (yield this.signMessage())
      // if (!signature) {
      //   snackController.error('Sign messeage is invalid')
      //   return
      // }
      const register = yield apiService.profiles.createUserProfile({
        ...signature,
        walletAddress: walletAddress || this.account,
        email,
        userName,
      })
      this.userProfile = { ...register?.profile }
      this.jwt = register.jwt
      localData.setAccessToken(register.jwt)
      localData.setLastSeen(moment().format('MMM,DD, YYYY'))
      snackController.success('Register successfully')

      yield this.checkUserWallet()
      localData.setFirstLoginState(this.userProfile?.totalPosts === 0 ? true : false)
      this.checkNewBie()
      window.location.replace('/')
    } catch (error: any) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }

  @asyncAction *signIn(showError = true) {
    try {
      this.isLoadingSignIn = true
      const signature = yield this.signMessage(showError)
      let res
      if (signature) res = yield apiService.users.signIn(signature)
      if (res) {
        this.jwt = res.jwt

        // fix mobx issue => not change this
        if (res.updatedUser) {
          this.userInfo = { ...res.updatedUser }
        }
        if (res.updatedUser?.investor) {
          this.investor = { ...res.updatedUser?.investor }
        }
        if (res.updatedUser?.profile) {
          this.userProfile = { ...res.updatedUser?.profile }
        }
        //end
        localData.setAccessToken(res.jwt)
        localData.setLastSeen(moment().format('MMM,DD, YYYY'))
        localData.setFirstLoginState(this.userProfile?.totalPosts === 0 ? true : false)
        window.location.replace('/')
      } else {
        showError && this.disconnect()
      }
      return true
    } catch (error: any) {
      showError && snackController.commonError(error)
    } finally {
      this.isLoadingSignIn = false
    }
  }

  @asyncAction *signUpWallet(publicAddress, showError = true) {
    try {
      yield apiService.users.signUp({ publicAddress })
    } catch (error) {
      const apiError = get(error, 'response.data.message')
      const errId = get(apiError, "[0]['messages'][0]['id']")
      if (errId === 'Auth.form.error.publicAddress.notInWhitelist') {
        this.account = ''
        this.selectedWallet = ''
        localData.setWalletSelected('')
        localData.setActiveWalletAddress('')
        this.setShowRequireLoginDialog(false)
        this.setNotInWhitelistDialog(true)
      } else {
        showError && snackController.commonError(error)
      }
    }
  }

  @action disconnect() {
    localData.setAccessToken('')
    localData.setActiveWalletAddress('')
    localData.setWalletSelected('')
    localData.setAccessToken('')
    localData.removeWalletConnect()
    if (connector) {
      connector.killSession()
      connector.off('connect')
      connector.off('session_update')
      connector.off('disconnect')
    }
    window.location.replace('/')
  }

  @action logout() {
    localData.setAccessToken('')
    appProvider.router.push('/')
    window.location.reload()
  }

  @asyncAction *checkUserWallet() {
    try {
      let data: any = undefined
      if (apiService) {
        if (this.jwt) {
          data = yield apiService.users.getMe()
          const res = yield apiService.users.find({ id: data.id }, { _limit: 1 })
          if (res.length) {
            data = res[0]
          }
        } else if (this.account) {
          const res = yield apiService.users.find({ username: this.account }, { _limit: 1 })
          if (res.length) {
            data = res[0]
          }
        }
      }

      if (data) {
        // fix mobx issue => not change this
        this.userInfo = { ...data }
        if (data.investor) {
          this.investor = { ...data?.investor }
        }
        if (data.profile) {
          this.userProfile = { ...data?.profile }
        }
        //end
      }
      return !!data
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  @computed get isLogin() {
    return !!this.jwt && !!this.userProfile
  }

  @computed get isKycPending() {
    return this.investor?.status === 'kyc-pending'
  }
  @computed get isKycFinalRejected() {
    return this.investor?.status === 'kyc-banned'
  }
  @computed get isKycVerified() {
    return this.investor?.status === 'kyc-verified'
  }

  @computed get kycStatusText() {
    if (this.isKycVerified) return { color: 'primary', text: 'Verified' }
    else if (this.isKycPending) return { color: 'error', text: 'Pending' }
    else return { color: 'error', text: 'Unverified' }
  }

  @computed get walletConnected() {
    return !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 4) + '...' + this.account.substr(this.account.length - 4)
  }

  @computed get shortAccount2() {
    if (!this.account) return ''
    return this.account.substr(0, 15) + '...' + this.account.substr(this.account.length - 8)
  }

  @computed get walletLast4Characters() {
    if (!this.account) return ''
    return this.account.slice(-4)
  }

  @computed get isRegisted() {
    return !!this.userProfile
  }

  @computed get isSocialLogin() {
    return this.userInfo && this.userInfo.provider !== 'local'
  }
}

export const walletStore = new WalletStore()
