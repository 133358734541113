import { userStore } from '@/stores/user-store'
import { reverse } from 'lodash'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/admin/delete/user' },
  {
    path: '/admin',
    name: 'Admin',
    component: { render: (c) => c('router-view') },
    children: [
      {
        path: '/',
        redirect: 'login',
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/modules/admin/pages/login.vue'),
        meta: {
          appbar: false,
          jwtRedirect: '/',
        },
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: { auth: true },
        component: () => import('@/modules/admin/pages/dashboard.vue'),
      },
      {
        path: 'delete',
        name: 'Delete',
        meta: { auth: true },
        component: () => import('@/modules/deleting/container/delete-container.vue'),
        children: [
          {
            path: '/',
            redirect: 'post',
          },
          {
            path: 'user',
            name: 'User',
            component: () => import('@/modules/deleting/pages/delete-user-page.vue'),
          },
          {
            path: 'post',
            name: 'Post',
            component: () => import('@/modules/deleting/pages/delete-post-page.vue'),
          },
          {
            path: 'dao',
            name: 'Dao',
            component: () => import('@/modules/deleting/pages/delete-dao-page.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // await when(() => walletStore.loaded)

  const requiredAuth = to.matched.some((m) => m.meta?.auth === true)
  const loggedIn = userStore.isLogin
  const jwt = userStore.jwt

  const loggedInRedirect = reverse([...to.matched]).find((x) => x.meta?.loggedInRedirect)?.meta?.loggedInRedirect
  const jwtRedirect = reverse([...to.matched]).find((x) => x.meta?.jwtRedirect)?.meta?.jwtRedirect

  if (jwt && jwtRedirect) {
    next(jwtRedirect)
  } else if (requiredAuth && !loggedIn) {
    next('/admin/login')
  } else if (loggedIn && !requiredAuth && loggedInRedirect) {
    next(loggedInRedirect)
  } else {
    next()
  }
})

export default router
