import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { localData } from './local-data'

export class UserStore {
  @observable jwt = localData.getAccessToken()
  @observable userInformation: any = {}
  @observable loaded = false

  constructor() {
    //
  }

  @action setJwt(jwt: string) {
    this.jwt = jwt
  }

  @action setUserInformation(userInfo: any) {
    this.userInformation = userInfo
  }

  @asyncAction *signIn(requestBody) {
    const res = yield apiService.apiHandler.signIn(requestBody)
    if(res) {
      this.setJwt(res.jwt)
      this.setUserInformation(res.user)
      localData.setAccessToken(res.jwt)
      appProvider.router.push("/admin/delete/user")
    }
  }

  @asyncAction *logout() {
    localData.setAccessToken('')
    appProvider.router.push('/admin/login')
  }

  @computed get isLogin() {
    return !!this.jwt && !!this.userInformation
  }

}

export const userStore = new UserStore()
